import styled from 'styled-components';

const StyledPage = styled.section`
  margin: 0 auto;
    
  &:after, &:before {
    width: 550px;
    max-width: 50%;
    height: 100%;
    content: '';
    position: absolute;
    top: 0;
    z-index: -1;
    background-size: cover;
  }
  &:after {
    right: 0;
    background-image: url(${props => props.theme.background.right});
    background-repeat: repeat-y;
    background-position: 100%;
  }
  &:before {
    left: 0;
    background-image: url(${props => props.theme.background.left});
    background-repeat: repeat-y;
    background-position: 0;
  }

  @media screen and (max-width: ${props => props.theme.breakpoint.max('small')}) {
    &:before {
      display: none;
    }
    &:after {
      width: 100%;
      max-width: 100%;
    }
  }
`;

export default StyledPage;
