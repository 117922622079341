import * as types from './types';
import produce from 'immer';
import setPublishedAt from '../../utils/setPublishedAt';

export const initialState = {
  contents: {},
  activeTab: 0
};

export const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case types.RETRIEVE_CONTENTS:
      return {
        ...state,
        contents: action.contents.reduce((acc, cur) => (
          {
            ...acc,
            [cur.id]: {
              ...cur,
              httpMethod: 'PUT'
            }
          }
        ), { ...state.contents })
      };
    case types.PUBLISHED_AT_CONTENT:
      return {
        ...state,
        contents: {
          ...state.contents,
          [action.content.id]: {
            ...state.contents[action.content.id],
            publishedAt: action.content.value
          }
        }
      };
    case types.NEW_CONTENT:
      return {
        ...state,
        activeTab: action.content.tempId,
        contents: {
          ...state.contents,
          [action.content.tempId]: {
            title: 'New Content',
            createdAt: action.content.createdAt,
            tempId: action.content.tempId
          }
        }
      };
    case types.CLEAR_CONTENTS:
      return { ...state, contents: { ...initialState.contents } };
    case types.SYNC_CONTENT_WITH_REMOTE:
      return {
        ...state,
        activeTab: action.content.id,
        contents: Object.keys(state.contents).reduce((acc, cur) => {
          if (parseInt(cur, 10) === action.oldContentId) {
            return Object.assign(acc, {
              [action.content.id]: {
                ...action.content,
                page: action.content.page.id
              }
            });
          }
          return Object.assign(acc, { [cur]: state.contents[cur] });
        }, {})
      };
    case types.DELETE_CONTENT: {
      const contents = { ...state.contents };
      delete contents[action.id.id];

      return {
        ...state,
        contents
      };
    }
    case types.UPDATE_CONTENT_TITLE:
      return {
        ...state,
        contents: {
          ...state.contents,
          [action.val.id]: { ...state.contents[action.val.id], title: action.val.value }
        }
      };
    case types.UPDATE_CONTENT_PUBLISHED:
      return {
        ...state,
        contents: {
          ...state.contents,
          [action.val.id]: { ...state.contents[action.val.id], publish_date: action.val.value, publishedAt: setPublishedAt(action.val.value) }
        }
      };
    case types.CONTENT_EDITED:
      return {
        ...state,
        contents: {
          ...state.contents,
          [action.val.id]: { ...state.contents[action.val.id], edited: action.val.edited }
        }
      };
    case types.UPDATE_TAB:
      return { ...state, activeTab: action.id };
    case types.PASTE_COPIED_CONTENT:
      return {
        ...state,
        contents: {
          ...state.contents,
          [action.data.key]: { ...action.data }
        }
      };
    case types.CLEAR_AND_SET_CONTENT:
      state.contents = action.contents.reduce((acc, cur) => (
        {
          ...acc,
          [cur.id]: {
            ...cur,
            httpMethod: 'PUT'
          }
        }
      ), {})
      break; 
    default:
      return state;
  }
});
