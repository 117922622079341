import setPublishedAt from '../../utils/setPublishedAt';
import * as types from './types';

export const initialState = {
  pages: {},
  pagination: {},
  loading: false,
  activePage: {
    id: 0,
    title: '',
    url: '',
    meta_data: { title: '', tags: '', description: '' },
    publish_date: null,
    publishedAt: null,
    background: 'default',
    type: 'page',
    in_le_scoop_module: true,
    inner_page_navigation: false,
    social_image: null,
    edited: false,
  }
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RETRIEVE_PAGES:
      return {
        ...state,
        pages: action.pages.data,
        pagination: action.pages.meta.pagination,
        loading: false
      };
    case types.RETRIEVE_PAGE:
      return {
        ...state,
        activePage: {
          ...state.activePage, ...action.page, edited: false, httpMethod: 'PUT'
        }
      };
    case types.NEW_PAGE:
      return { ...state, activePage: { ...state.activePage, ...action.page } };
    case types.CLEAR_ACTIVE_PAGE:
      return { ...state, activePage: { ...initialState.activePage } };
    case types.UPDATE_PAGE_ID:
      return { ...state, activePage: { ...state.activePage, id: action.value } };
    case types.UPDATE_PAGE_TITLE:
      return { ...state, activePage: { ...state.activePage, title: action.value } };
    case types.UPDATE_PAGE_URL:
      return { ...state, activePage: { ...state.activePage, url: action.value } };
    case types.UPDATE_PAGE_META_DATA:
      return { ...state, activePage: { ...state.activePage, meta_data: action.value } };
    case types.UPDATE_PAGE_PUBLISH_DATE:
      return { ...state, activePage: { ...state.activePage, publish_date: action.value, publishedAt: setPublishedAt(action.value) } };
    case types.UPDATE_PAGE_CONTENT:
      return { ...state, activePage: { ...state.activePage, content: [...state.activePage.content, action.value] } };
    case types.UPDATE_PAGE_BACKGROUND:
      return { ...state, activePage: { ...state.activePage, background: action.value } };
    case types.UPDATE_PAGE_TYPE:
      return { ...state, activePage: { ...state.activePage, type: action.value } };
    case types.UPDATE_PAGE_IN_LE_SCOOP_MODULE:
      return { ...state, activePage: { ...state.activePage, in_le_scoop_module: action.value } };
    case types.UPDATE_PAGE_INNER_NAVIGATION:
      return { ...state, activePage: { ...state.activePage, inner_page_navigation: action.value } };
    case types.UPDATE_PAGE_IMAGE:
      return { ...state, activePage: { ...state.activePage, [action.value.key]: action.value.value } };
    case types.PAGE_EDITED:
      return { ...state, activePage: { ...state.activePage, edited: action.value } };
    case types.PAGE_HTTP_METHOD:
      return { ...state, activePage: { ...state.activePage, httpMethod: action.value } };
    case types.UPDATE_HIERARCHICAL_MENU:
      return {
        ...state,
        activePage: {
          ...state.activePage,
          hierarchical_menu: action.value
        }
      };
    case types.PASTE_PAGE_DATA:
      return { ...state, activePage: { ...state.activePage, ...action.value } };
    case types.PAGE_LOADING:
      return {...state, loading: action.value}  
    default:
      return state;
  }
};
