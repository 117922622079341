import React, { Component } from 'react';
import Error from 'next/error';
import PropTypes from 'prop-types';
// import * as Sentry from '@sentry/node';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  static async getInitialProps({ res, err }) {
    let statusCode;

    if (res) statusCode = { res };
    else if (err) statusCode = { err };

    return { statusCode };
  }

  componentDidCatch(error) {
   // Sentry.captureException(error);
  }

  render() {
    return (
      this.state.hasError
        ? <Error statusCode={this.props.statusCode} />
        : this.props.children
    );
  }
}

ErrorBoundary.defaultProps = {
  statusCode: null
};


ErrorBoundary.propTypes = {
  children: PropTypes.any.isRequired,
  statusCode: PropTypes.number
};

export default ErrorBoundary;
