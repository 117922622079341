import { css } from 'styled-components';

const fonts = css`
/*
 * This font software is the property of Commercial Type.
 * You may not modify the font software, use it on another website, or install it on a computer.
 *
 * License information is available at http://commercialtype.com/eula
 * For more information please visit Commercial Type at http://commercialtype.com or email us at info[at]commercialtype.com
 *
 * Copyright (C) 2016 Schwartzco Inc.
 * License: 1611-CRGMPW
 */

@font-face {
  font-family: 'Canela Web';
  src: url('/fonts/Canela-Light-Web.eot');
  src: url('/fonts/Canela-Light-Web.eot?#iefix') format('embedded-opentype'),
  url('/fonts/Canela-Light-Web.woff2') format('woff2'),
  url('/fonts/Canela-Light-Web.woff') format('woff');
  font-weight:  normal;
  font-style:   normal;
  font-stretch: normal;
}

/*
 * @license
 * MyFonts Webfont Build ID 3312130, 2016-11-22T13:53:42-0500
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: PlantinMTPro-Regular by Monotype
 * URL: http://www.myfonts.com/fonts/mti/plantin/pro-roman/
 *
 * Webfont: PlantinMTPro-Italic by Monotype
 * URL: http://www.myfonts.com/fonts/mti/plantin/pro-italic/
 *
 *
 * License: http://www.myfonts.com/viewlicense?type=web&buildid=3312130
 * Licensed pageviews: 250,000
 * Webfonts copyright: Font software Copyright 1990, 1995, 2002 Adobe Systems Incorporated. All rights reserved. Typeface designs Copyright The Monotype Corporation. All rights reserved.
 *
 * © 2016 MyFonts Inc
 */

@font-face {
  font-family: 'Plantin Web';
  src: url('/fonts/328A02_0_0.eot');
  src: url('/fonts/328A02_0_0.eot?#iefix') format('embedded-opentype'),
  url('/fonts/328A02_0_0.woff2') format('woff2'),
  url('/fonts/328A02_0_0.woff') format('woff'),
  url('/fonts/328A02_0_0.ttf') format('truetype');
}

@font-face {
  font-family: 'Plantin Web';
  src: url('/fonts/328A02_1_0.eot');
  src: url('/fonts/328A02_1_0.eot?#iefix') format('embedded-opentype'),
  url('/fonts/328A02_1_0.woff2') format('woff2'),
  url('/fonts/328A02_1_0.woff') format('woff'),
  url('/fonts/328A02_1_0.ttf') format('truetype');
  font-style: italic;
}

@font-face {
  font-family: 'GT Pressura Web';
  src: url('/fonts/GT-Pressura-Regular.eot');
  src: url('/fonts/GT-Pressura-Regular.eot?#iefix') format('embedded-opentype'),
  url('/fonts/GT-Pressura-Regular.woff') format('woff'),
  url('/fonts/GT-Pressura-Regular.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'GT Walsheim Web';
  src: url('/fonts/GT-Walsheim-Pro-Regular.eot');
  src: url('/fonts/GT-Walsheim-Pro-Regular.eot?#iefix') format('embedded-opentype'),
  url('/fonts/GT-Walsheim-Pro-Regular.woff') format('woff'),
  url('/fonts/GT-Walsheim-Pro-Regular.ttf') format('truetype');
  font-style: normal;
}
`;

export default fonts;
