export const RETRIEVE_PAGES = 'RETRIEVE_PAGES';
export const RETRIEVE_PAGE = 'RETRIEVE_PAGE';
export const NEW_PAGE = 'NEW_PAGE';
export const CLEAR_ACTIVE_PAGE = 'CLEAR_ACTIVE_PAGE';
export const UPDATE_PAGE_ID = 'UPDATE_PAGE_ID';
export const UPDATE_PAGE_TITLE = 'UPDATE_PAGE_TITLE';
export const UPDATE_PAGE_URL = 'UPDATE_PAGE_URL';
export const UPDATE_PAGE_META_DATA = 'UPDATE_PAGE_META_DATA';
export const UPDATE_PAGE_PUBLISH_DATE = 'UPDATE_PAGE_PUBLISH_DATE';
export const UPDATE_PAGE_PUBLISHED_AT = 'UPDATE_PAGE_PUBLISHED_AT';
export const UPDATE_PAGE_CONTENT = 'UPDATE_PAGE_CONTENT';
export const UPDATE_PAGE_BACKGROUND = 'UPDATE_PAGE_BACKGROUND';
export const UPDATE_PAGE_TYPE = 'UPDATE_PAGE_TYPE';
export const UPDATE_PAGE_IN_LE_SCOOP_MODULE = 'UPDATE_PAGE_IN_LE_SCOOP_MODULE';
export const UPDATE_PAGE_INNER_NAVIGATION = 'UPDATE_PAGE_INNER_NAVIGATION';
export const UPDATE_PAGE_IMAGE = 'UPDATE_PAGE_IMAGE';
export const PAGE_EDITED = 'PAGE_EDITED';
export const PAGE_HTTP_METHOD = 'PAGE_HTTP_METHOD';
export const UPDATE_HIERARCHICAL_MENU = 'UPDATE_HIERARCHICAL_MENU';
export const PASTE_PAGE_DATA = 'PASTE_PAGE_DATA';
export const PAGE_LOADING = 'PAGE_LOADING';
