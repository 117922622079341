import reset from './reset';
import fonts from './fonts';
import theme from './theme';

const GlobalStyles = `
  ${reset}
  ${fonts}

  html {
    box-sizing: border-box;
    font-size: 10px;
  }

  *, *::before, *::after {
    box-sizing: inherit;
  }

  body {
    background-color: ${theme.color.brandNeutral};
    font-family: ${theme.font.serif};
    font-size: 1.5rem;
    line-height: 2;
    margin: 0;
    padding: 0;
  }

  a {
    color: ${theme.color.brand};
    display: inline-block;
    font-family: ${theme.font.sans};
    letter-spacing: 0.05rem;
    text-decoration: underline;
  }

  .react-contextmenu--visible {
    margin: 2rem 0 0 -2rem;
    z-index: 1000;
  }

  .react-contextmenu-item {
    font-family: ${theme.font.caption};
    font-size: ${theme.modularScale.small};
    text-transform: uppercase;
    width: 100%;
    height: 4rem;
    line-height: 4rem;
    background-color: ${theme.color.brand};
    color: ${theme.color.white};
    padding: 0 2rem;
    cursor: pointer;
  }
`;

export default GlobalStyles;
