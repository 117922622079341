export const RETRIEVE_MODULES = 'RETRIEVE_MODULES';
export const ACTIVE_MODULE = 'ACTIVE_MODULE';
export const MODULE_EDITED = 'MODULE_EDITED';
export const MODULE_DELETED = 'MODULE_DELETED';
export const NEW_MODULE = 'NEW_MODULE';
export const CLEAR_MODULES = 'CLEAR_MODULES';
export const SYNC_MODULES_WITH_CONTENT_ID = 'SYNC_MODULES_WITH_CONTENT_ID';
export const DELETE_MODULE = 'DELETE_MODULE';
export const RETRIEVE_MODULENAMES = 'RETRIEVE_MODULENAMES';
export const MODULESNAMES_VISIBLE = 'MODULESNAMES_VISIBLE';
export const UPDATE_TEMPLATE_CONTENT = 'UPDATE_TEMPLATE_CONTENT';
export const CREATE_NEW_TEMPLATE_NESTED = 'CREATE_NEW_TEMPLATE_NESTED';
export const DELETE_TEMPLATE_NESTED = 'DELETE_TEMPLATE_NESTED';
export const UPDATE_TEMPLATE_NESTED_CONTENT = 'UPDATE_TEMPLATE_NESTED_CONTENT';
export const UPDATE_NESTED_KEY = 'UPDATE_NESTED_KEY';
export const CHANGE_POSITION = 'CHANGE_POSITION';
export const UPDATE_DRAGGABLE = 'UPDATE_DRAGGABLE';
export const STORIES_VISIBLE = 'STORIES_VISIBLE';
export const NEW_COPIED_MODULE = 'NEW_COPIED_MODULE';
