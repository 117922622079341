import * as types from './types';

export const initialState = {};

export const reducer = ( state = initialState, action ) => {
  switch (action.type) {
    case types.RETRIEVE_MOB_NAV: {
      return {
        ...state,
        ...action.mobileNav
      };
    }
    case types.ADD_MOB_NAV_ENTRY: {
      const navCopy = [...state.attributes.entries];

      navCopy.push({
        name: '',
        url: '',
        flair: false,
        highlighted: false,
        image: null
      });
  
      return {
        ...state,
        attributes: {
          ...state.attributes,
          entries: [...navCopy]
        }
      };
    }
    case types.REMOVE_MOB_NAB_ENTRY: {
      const navCopy = [...state.attributes.entries];
      navCopy.splice(action.value, 1);

      return {
        ...state,
        attributes: {
          ...state.attributes,
          entries: [...navCopy]
        }
      };
    }
    case types.MOVE_MOB_NAV_ENTRY: {
      const navCopy = [...state.attributes.entries];
      const newIndex = action.value === 'up' ? action.index - 1 : action.index + 1;
      const entryCopy = navCopy[action.index];
      navCopy[action.index] = navCopy[newIndex];
      navCopy[newIndex] = entryCopy;

      return {
        ...state,
        attributes: {
          ...state.attributes,
          entries: [...navCopy]
        }
      };
    }
    case types.UPDATE_MOB_NAV_NAME: {
      const navCopy = [...state.attributes.entries];
      navCopy[action.index].name = action.value;

      return {
        ...state,
        attributes: {
          ...state.attributes,
          entries: [...navCopy]
        }
      };
    }
    case types.UPDATE_MOB_NAV_URL: {
      const navCopy = [...state.attributes.entries];
      navCopy[action.index].url = action.value.replace(/^\/+/g, '');

      return {
        ...state,
        attributes: {
          ...state.attributes,
          entries: [...navCopy]
        }
      };
    }
    case types.UPDATE_MOB_NAV_FLAIR: {
      const navCopy = [...state.attributes.entries];
      navCopy[action.index].flair = action.value;

      return {
        ...state,
        attributes: {
          ...state.attributes,
          entries: [...navCopy]
        }
      };
    }
    case types.UPDATE_MOB_NAV_HIGHLIGHTED: {
      const navCopy = [...state.attributes.entries];
      navCopy[action.index].highlighted = action.value;

      return {
        ...state,
        attributes: {
          ...state.attributes,
          entries: [...navCopy]
        }
      };
    }
    case types.UPDATE_MOB_NAV_IMAGE: {
      const navCopy = [...state.attributes.entries];
      navCopy[action.index].image = action.value.value;

      return {
        ...state,
        attributes: {
          ...state.attributes,
          entries: [...navCopy]
        }
      };
    }
    default:
      return state;
  }
}
