import * as types from './types';

export const initialState = {
  productSearch: '',
  products: [],
  productSearchVisible: false,
  linkedFormId: 0,
  searching: false
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_PRODUCT_SEARCH_TERM:
      return Object.assign({}, state, {
        productSearch: action.value
      });
    case types.UPDATE_PRODUCT_LIST:
      return Object.assign({}, state, {
        products: [...action.content.products]
      });
    case types.SHOW_PRODUCT_SEARCH:
      return Object.assign({}, state, {
        productSearchVisible: action.value
      });
    case types.SEARCHING:
      return Object.assign({}, state, {
        searching: action.bool
      });
    default:
      return state;
  }
};
