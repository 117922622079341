import * as types from './types';

export const initialState = {};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RETRIEVE_PROMO_BAR: {
      return {
        ...state,
        ...action.promoBar
      };
    }
    case types.ADD_PROMO_ENTRY: {
      const entriesCopy = [...state.attributes.entries];

      entriesCopy.push({
        content: '',
        starts_at: new Date(),
        ends_at: new Date(),
        ignore_dates: true
      });

      return {
        ...state,
        attributes: {
          ...state.attributes,
          entries: [...entriesCopy]
        }
      };
    }
    case types.REMOVE_PROMO_ENTRY: {
      const entriesCopy = [...state.attributes.entries];
      entriesCopy.splice(action.value, 1);

      return {
        ...state,
        attributes: {
          ...state.attributes,
          entries: [...entriesCopy]
        }
      };
    }
    case types.MOVE_PROMO_ENTRY: {
      const entriesCopy = [...state.attributes.entries];
      const newIndex = action.value === 'up' ? action.index - 1 : action.index + 1;
      const entryCopy = entriesCopy[action.index];
      entriesCopy[action.index] = entriesCopy[newIndex];
      entriesCopy[newIndex] = entryCopy;

      return {
        ...state,
        attributes: {
          ...state.attributes,
          entries: [...entriesCopy]
        }
      };
    }
    case types.UPDATE_PROMO_CONTENT: {
      const entriesCopy = [...state.attributes.entries];
      entriesCopy[action.index].content = action.value;

      return {
        ...state,
        attributes: {
          ...state.attributes,
          entries: [...entriesCopy]
        }
      };
    }
    case types.UPDATE_PROMO_STARTS_AT: {
      const entriesCopy = [...state.attributes.entries];
      entriesCopy[action.index].starts_at = action.value;

      return {
        ...state,
        attributes: {
          ...state.attributes,
          entries: [...entriesCopy]
        }
      };
    }
    case types.UPDATE_PROMO_ENDS_AT: {
      const entriesCopy = [...state.attributes.entries];
      if (new Date(action.value) < new Date(entriesCopy[action.index].starts_at)) {
        entriesCopy[action.index].ends_at = entriesCopy[action.index].starts_at;
      } else {
        entriesCopy[action.index].ends_at = action.value;
      }
      return {
        ...state,
        attributes: {
          ...state.attributes,
          entries: [...entriesCopy]
        }
      };
    }
    case types.UPDATE_PROMO_IGNORE_DATES: {
      const entriesCopy = [...state.attributes.entries];
      entriesCopy[action.index].ignore_dates = action.value;
      return {
        ...state,
        attributes: {
          ...state.attributes,
          entries: [...entriesCopy]
        }
      };
    }
    default: 
      return state;
  }
}
