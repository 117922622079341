import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { reducer as globalReducer } from './global/reducer';
import { reducer as authReducer } from './auth/reducer';
import { reducer as pageReducer } from './pages/reducer';
import { reducer as contentReducer } from './contents/reducer';
import { reducer as moduleReducer } from './modules/reducer';
import { reducer as mobileNavReducer } from './mobilenav/reducer';
import { reducer as promoBarReducer } from './promobar/reducer';
import { reducer as productsReducer } from './products/reducer';

export const rootReducer = combineReducers({
  global: globalReducer,
  auth: authReducer,
  pages: pageReducer,
  contents: contentReducer,
  mobileNav: mobileNavReducer,
  promoBar: promoBarReducer,
  modules: moduleReducer,
  products: productsReducer
});

const makeConfiguredStore = (reducer, initialState) => createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(thunkMiddleware))
);

const persistConfig = {
  key: 'nextjs',
  storage,
  whitelist: ['auth']
};

export const initStore = (initialState = {}, { isServer, req, debug, storeKey }) => {
  if (isServer) {
    return makeConfiguredStore(rootReducer, initialState);
  }
  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const store = makeConfiguredStore(persistedReducer, initialState);
  store.__persistor = persistStore(store); // eslint-disable-line
  return store;
};
