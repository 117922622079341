const breakpoint = {
  small: '768px',
  medium: '1024px',
  large: '1440px',
  max: bp => `${parseInt(breakpoint[bp], 10) - 1}px`
};

const color = {
  white: '#FFFFFF',
  brand: '#3150A2',
  brandLight: '#9CB1DC',
  brandNeutral: '#FAEFD9',
  brandPink: '#F8A08F',
  brandError: '#f86B4E'
};

const font = {
  sans: 'GT Walsheim Web, Helvetica, sans-serif',
  serif: 'Plantin Web, Times New Roman, Times, serif',
  caption: 'GT Pressura Web, Helvetica, Arial, sans-serif',
  heading: 'Canela Web, Times New Roman, Times, serif'
};

const modularScale = {
  small: '1.2rem',
  base: '1.5rem',
  medium: '1.875rem',
  large: '2.3438rem',
  xlarge: '2.9297rem',
  '2xlarge': '3.6621rem',
  '3xlarge': '4.5776rem',
  '4xlarge': '5.722rem'
};

const width = {
  small: '600px',
  medium: '900px',
  large: '1200px'
};

const background = {
  left: '/images/maisonette-bg-1-left.jpg',
  right: '/images/maisonette-bg-1-right.jpg'
};

const center = {
  standard: `margin: 0 auto;
  @media screen and (min-width: ${breakpoint.small}) {
    width: ${width.small};
  }

  @media screen and (min-width: ${breakpoint.medium}) {
    width: ${width.medium};
  }

  @media screen and (min-width: ${breakpoint.large}) {
    width: ${width.large};
  }`
};

const dialog = {
  default:
    `align-content: top;
    background: ${color.brandNeutral};
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-template-rows: min-content;
    grid-gap: 1rem;
    overflow: auto;
    padding: 10vh 1vw;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
    height: 100vh;
    z-index: 10;`,
  grid:
    `align-content: top;
    background: ${color.brandNeutral};
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: max-content;
    grid-gap: 1rem;
    overflow: auto;
    padding: 10vh 1vw;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
    height: 100vh;
    z-index: 10;
    @media screen and (min-width: ${breakpoint.small}) {
      grid-template-columns: 1fr 1fr;
    }

    @media screen and (min-width: ${breakpoint.medium}) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }`
};

const loader = (size = 3, clr = color.brand) => `
  ::after {
    animation: spin .5s infinite linear;
    border: 0.25rem solid ${clr};
    border-radius: 290486px;
    border-right-color: transparent;
    border-top-color: transparent;
    content: '';
    height: ${size}rem;
    left: calc(50% - (${size}rem / 2));
    position: absolute;
    top: calc(50% - (${size}rem / 2));
    width: ${size}rem;

    @keyframes spin {
      from { transform: rotate(0) }
      to { transform: rotate(359deg) }
    }
  }
`;

const GlobalTheme = {
  breakpoint,
  color,
  font,
  modularScale,
  width,
  background,
  center,
  dialog,
  loader
};

export default GlobalTheme;
