import React from 'react';
import { Provider } from 'react-redux';
import withRedux from 'next-redux-wrapper';
import { PersistGate } from 'redux-persist/integration/react';
import App from 'next/app';
import getConfig from 'next/config';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
// import * as Sentry from '@sentry/node';
import * as smoothscroll from 'smoothscroll-polyfill';

import GlobalTheme from '../theme/theme';
import GlobalStyles from '../theme/styles';
import StyledPage from '../theme/page';

import ErrorBoundary from '../utils/error-boundary';

import { initStore } from '../store';

// const {
//   release,
//   sentryEnv,
//   sentryClientDsn
// } = getConfig().publicRuntimeConfig;

// Sentry.init({
//   environment: sentryEnv,
//   dsn: sentryClientDsn,
//   release
// });

const GlobalStyle = createGlobalStyle`${GlobalStyles}`;

export default withRedux(initStore)(class MaisonetteCMS extends App {
  static async getInitialProps({ Component, ctx }) {
    let pageProps = {};
    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }
    return { pageProps: { ...pageProps } };
  }

  componentDidCatch(error, errorInfo) {
    // Sentry.withScope((scope) => {
    //   Object.keys(errorInfo).forEach((key) => {
    //     scope.setExtra(key, errorInfo[key]);
    //   });
    //  // Sentry.captureException(error);
    // });

    super.componentDidCatch(error, errorInfo);
  }

  conponentDidMount() {
    smoothscroll.polyfill();
    global.history.scrollRestoration = 'manual';
  }

  render() {
    const { Component, pageProps, store } = this.props;

    return (
      <Provider store={store}>
        {/* eslint-disable-next-line no-underscore-dangle */}
        <PersistGate persistor={store.__persistor}>
          <ErrorBoundary>
            <ThemeProvider theme={GlobalTheme}>
              <StyledPage suppressClassNameWarning>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <GlobalStyle />
                <Component {...pageProps} />
              </StyledPage>
            </ThemeProvider>
          </ErrorBoundary>
        </PersistGate>
      </Provider>
    );
  }
});
