import * as types from './types';
import produce from 'immer';

export const initialState = {
  modules: {},
  modulenames: [],
  modulenamesVisible: false,
  storiesVisible: false
};

export const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case types.RETRIEVE_MODULES:
      return Object.assign({}, state, {
        modules: action.modules.reduce((acc, cur) => Object.assign(
          {},
          acc,
          {
            [`${cur.id}-${cur.__component}`]: {
              ...cur,
              httpMethod: 'PUT',
              edited: false,
              deleted: false,
              active: false
            }
          }
        ), {})
      });
    case types.ACTIVE_MODULE:
      return Object.assign({}, state, {
        modules: Object.assign(
          {},
          state.modules,
          { [action.module.id]: { ...state.modules[action.module.id], active: action.module.value } }
        )
      });
    case types.MODULE_EDITED:
      return Object.assign({}, state, {
        modules: Object.assign(
          {},
          state.modules,
          { [action.val.id]: { ...state.modules[action.val.id], edited: action.val.edited } }
        )
      });
    case types.MODULE_DELETED:
      return Object.assign({}, state, {
        modules: Object.assign(
          {},
          state.modules,
          { [action.val.id]: { ...state.modules[action.val.id], deleted: action.val.deleted, formId: action.val.formId } }
        )
      });
    case types.NEW_MODULE:
      return Object.assign({}, state, {
        modules: Object.assign({}, state.modules, { [action.module.moduleId]: { ...action.module, httpMethod: 'POST', edited: false } })
      });
    case types.CLEAR_MODULES:
      return Object.assign({}, state, {
        modules: Object.assign({}, initialState.modules)
      });
    case types.SYNC_MODULES_WITH_CONTENT_ID:
      return Object.assign({}, state, {
        modules: Object.keys(state.modules).length > 0
          ? Object.keys(state.modules).reduce((acc, cur) => {
            if (state.modules[cur].content !== action.oldContentId) {
              return Object.assign(acc, { [cur]: state.modules[cur] });
            }
            return Object.assign(
              acc,
              {
                [cur]:
                  Object.assign(
                    state.modules[cur],
                    { content: action.newContentId }
                  )
              }
            );
          }, {})
          : state.modules
      });
    case types.DELETE_MODULE:
      return Object.assign({}, state, {
        modules: Object.keys(state.modules).length > 1
          ? Object.keys(state.modules).reduce((acc, cur) => {
            if (parseInt(cur, 10) !== action.id) {
              return Object.assign(acc, { [cur]: state.modules[cur] });
            }
            return acc;
          }, {})
          : {}
      });
    case types.RETRIEVE_MODULENAMES:
      return Object.assign({}, state, {
        modulenames: [
          ...action.modulenames
        ]
      });
    case types.MODULESNAMES_VISIBLE:
      return Object.assign({}, state, {
        modulenamesVisible: action.visibility
      });
    case types.UPDATE_TEMPLATE_CONTENT:
      return Object.assign({}, state, {
        modules: Object.assign(
          {},
          state.modules,
          {
            [action.module.id]:
              {
                ...state.modules[action.module.id],
                Content: Object.assign(
                  {},
                  state.modules[action.module.id].Content,
                  { [action.module.key]: action.module.value }
                )
              }
          }
        )
      });
    case types.CREATE_NEW_TEMPLATE_NESTED:
      let content = state.modules[action.content.id].Content;
      // check if its elements exist, then append
      if (content[action.content.key]) {
        content[action.content.key] = [...content[action.content.key], {}]
      } else {
        content[action.content.key] = [{}];
      }

      break;
    case types.DELETE_TEMPLATE_NESTED:
      // TODO: refactor this and UPDATE_TEMPLATE_NESTED_CONTENT below      

      if (action.content.element === 'products') {
        // filter out product by slug because the index of the returned Algolia product
        // doesn't always match the index in the product data
        const deleteProducts = state.modules[action.content.id].Content[action.content.element]
          .filter((product) => product.product_slug !== action.content.productSlug);
        state.modules[action.content.id].Content[action.content.element] = deleteProducts;
      } else {
        const deleteElements = state.modules[action.content.id].Content[action.content.element]
          .filter((element, index) => index !== action.content.elementId);
        state.modules[action.content.id].Content[action.content.element] = deleteElements;
      }

      break;
    case types.UPDATE_TEMPLATE_NESTED_CONTENT:
      // handles modules with elements or products attributes
      /*
        action.content: {
          id: formId
          element: attribute array name, either elements or products
          elementId: index of component in the attribute array,
          value: component data (product or element data),
          key: attribute name (elements only)
        }
      */

      if (action.content.element === 'products') {
        const products = state.modules[action.content.id].Content[action.content.element];
        products[action.content.elementId] = {
          ...products[action.content.elementId],
          ...action.content.value
        };
      } else {
        const elements = state.modules[action.content.id].Content[action.content.element];
        elements[action.content.elementId] = {
          ...elements[action.content.elementId],
          [action.content.key]: action.content.value
        };
      }

      break;
    case types.UPDATE_NESTED_KEY:
      /*
        Updates element or product position in a module
          action.content: {
            id: formId,
            element: attribute name (elements or products),
            component: component being moved,
            originalIndex: component's original index,
            newIndex: component's new index
          }
       */
      // TODO: this doesn't really work with products because of the stored vs Algolia returned products order
      // create copy of components
      const componentsCopy = [...state.modules[action.content.id].Content[action.content.element]];
      // remove component at original position
      componentsCopy.splice(action.content.originalIndex, 1);
      // add component at new position
      componentsCopy.splice(action.content.newIndex, 0, action.content.component);
      state.modules[action.content.id].Content[action.content.element] = componentsCopy;

      break;
    case types.CHANGE_POSITION:
      return Object.assign({}, state, {
        modules: Object.assign(
          {},
          state.modules,
          { [action.module.id]: { ...state.modules[action.module.id], position: action.module.position } }
        )
      });
    case types.UPDATE_DRAGGABLE:
      return Object.assign({}, state, {
        modules: Object.assign(
          {},
          state.modules,
          { [action.module.id]: { ...state.modules[action.module.id], draggable: action.module.value } }
        )
      });
    case types.STORIES_VISIBLE:
      return Object.assign({}, state, {
        storiesVisible: action.visibility
      });
    case types.NEW_COPIED_MODULE:
      return Object.assign({}, state, {
        modules: Object.assign({}, state.modules, { [action.module.id]: { ...action.module, httpMethod: 'POST', edited: true } })
      });
    default:
      return state;
  }
});
