export const RETRIEVE_MOB_NAV = 'RETRIEVE_MOB_NAV';

export const ADD_MOB_NAV_ENTRY = 'ADD_MOB_NAV_ENTRY';
export const REMOVE_MOB_NAB_ENTRY = 'REMOVE_MOB_NAB_ENTRY';
export const MOVE_MOB_NAV_ENTRY = 'MOVE_MOB_NAV_ENTRY';
export const UPDATE_MOB_NAV_NAME = 'UPDATE_MOB_NAV_NAME';
export const UPDATE_MOB_NAV_URL = 'UPDATE_MOB_NAV_URL';
export const UPDATE_MOB_NAV_FLAIR = 'UPDATE_MOB_NAV_FLAIR';
export const UPDATE_MOB_NAV_HIGHLIGHTED = 'UPDATE_MOB_NAV_HIGHLIGHTED';
export const UPDATE_MOB_NAV_IMAGE = 'UPDATE_MOB_NAV_IMAGE';
