import * as types from './types';

export const initialState = {
  title: '',
  type: '',
  edited: false,
  valid: false,
  images: [],
  activeForm: {},
  modulesCollapsed: false,
  help: {
    visible: false,
    type: 'altText',
    posX: 'left'
  }
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GLOBAL_TITLE:
      return Object.assign({}, state, {
        title: action.value
      });
    case types.GLOBAL_TYPE:
      return Object.assign({}, state, {
        type: action.value
      });
    case types.GLOBAL_EDITED:
      return Object.assign({}, state, {
        edited: action.value
      });
    case types.GLOBAL_VALID:
      return Object.assign({}, state, {
        valid: action.value
      });
    case types.GLOBAL_IMAGES:
      return Object.assign({}, state, {
        images: [
          action.value,
          ...state.images
        ]
      });
    case types.REMOVE_GLOBAL_IMAGE:
      return Object.assign({}, state, {
        images: state.images
          .filter(sImages => sImages.storeNameId !== action.value.id
            || sImages.imageKey !== action.value.key)
      });
    case types.UPDATE_FORM_ID:
      return Object.assign({}, state, {
        activeForm: Object.assign({}, state.activeForm,
          {
            formName: action.value.name,
            id: action.value.id,
            updateType: action.value.updateType,
            elementName: action.value.elementName,
            elementId: action.value.elementId
          })
      });
    case types.GLOBAL_HELP_VISIBLE:
      return Object.assign({}, state, {
        help: {
          ...state.help,
          visible: action.value
        }
      });
    case types.GLOBAL_HELP_POSITION_X:
      return Object.assign({}, state, {
        help: {
          ...state.help,
          posX: action.value
        }
      });
    case types.GLOBAL_HELP_TYPE:
      return Object.assign({}, state, {
        help: {
          ...state.help,
          type: action.value
        }
      });
    case types.SET_MODULES_COLLAPSED:
      return Object.assign({}, state, {
        modulesCollapsed: action.value
      })
    default:
      return state;
  }
};
