export const RETRIEVE_CONTENTS = 'RETRIEVE_CONTENTS';
export const PUBLISHED_AT_CONTENT = 'PUBLISHED_AT_CONTENT';
export const NEW_CONTENT = 'NEW_CONTENT';
export const CLEAR_CONTENTS = 'CLEAR_CONTENTS';
export const SYNC_CONTENT_WITH_REMOTE = 'SYNC_CONTENT_WITH_REMOTE';
export const DELETE_CONTENT = 'DELETE_CONTENT';
export const UPDATE_CONTENT_TITLE = 'UPDATE_CONTENT_TITLE';
export const UPDATE_CONTENT_PUBLISHED = 'UPDATE_CONTENT_PUBLISHED';
export const CONTENT_EDITED = 'CONTENT_EDITED';
export const UPDATE_TAB = 'UPDATE_TAB';
export const PASTE_COPIED_CONTENT = 'PASTE_COPIED_CONTENT';
export const CLEAR_AND_SET_CONTENT = 'CLEAR_AND_SET_CONTENT';
