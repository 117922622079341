import * as types from './types';

export const initialState = {
  userToken: {
    user: {},
    jwt: '',
  },
  user: '',
  username: '',
  email: '',
  password: '',
  showPassword: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.USER_SIGNIN:
      return Object.assign({}, state, {
        userToken: {user: action.user, jwt: action.jwt},
        username: action.user.username
      });

    case types.USER_SIGNOUT:
      return Object.assign({}, state, {
        userToken: ''
      });

    case types.TEXT_INPUT_UPDATE:
      return Object.assign({}, state, {
        user: action.value
      });

    case types.USER_INPUT_UPDATE:
      return Object.assign({}, state, {
        username: action.value
      });

    case types.EMAIL_INPUT_UPDATE:
      return Object.assign({}, state, {
        email: action.value
      });

    case types.PASSWORD_INPUT_UPDATE:
      return Object.assign({}, state, {
        password: action.value
      });

    case types.PASSWORD_BOOLEAN_UPDATE:
      return Object.assign({}, state, {
        showPassword: action.value
      });

    default:
      return state;
  }
};
